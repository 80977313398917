import React, {useState, useEffect} from 'react';
import Paper from '@mui/material/Paper';
import { Row, Col,} from "react-bootstrap";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import * as FaIcons from "react-icons/fa";
import * as RiIcons from "react-icons/ri";
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const columns = [
    { id: '1', label: 'Ref code', minWidth: 170,span: false },
    { id: '1', label: 'Bar code', minWidth: 170,span: false },
    { id: '2', label: 'Product Name', minWidth: 170,span: false },
    { id: '3', label: 'Color', minWidth: 170,span: false },
    { id: '4', label: 'Size', minWidth: 170,span: false },
    { id: '5', label: 'Stock', minWidth: 170,span: false },
  ];

  const rows = [
    {
      demo: 'test',
    }
  ];

function CurrentStockReport(props) {
    const [usercompanyid, setUsercompanyid] = useState("")
    const [userbranchid, setUserbranchid] = useState("")
    const [usertype, setUsertype] = useState("")
    const [source, setSource] = useState([])
    const [downloadlink, setDownloadlink] = React.useState("");
    const [listtype, setListtype] = React.useState(0);
    
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
   getData()
  }, [listtype]);

  const getData = () => {
    if(listtype == 0) {
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        const data = {
            "companyid": company,
            "type": userdata.user_type,
            "producttype": "",
            "branchid" : branch
        }
        fetch(global.url + "currentStockReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("stock report", responseJson)
            setSource(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    else{
        var userdata = JSON.parse(localStorage.getItem("user"))
        var branch = JSON.parse(localStorage.getItem("branch"))
        var company = JSON.parse(localStorage.getItem("company"))
        const data = {
            "companyid": company,
            "type": userdata.user_type,
            "producttype": "masterwise",
            "branchid" : branch
        }
        fetch(global.url + "currentStockReport", {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
        .then((response)=> response.json())
        .then((responseJson)=>{
            console.log("stock report", responseJson)
            setSource(responseJson.data)
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    
  }

  const searchProduct = (refcode, barcode, proname) => {
    var userdata = JSON.parse(localStorage.getItem("user"))
    var branch = JSON.parse(localStorage.getItem("branch"))
    var company = JSON.parse(localStorage.getItem("company"))
    const data = {
        "companyid": company,
        "type": userdata.user_type,
        "refcode": refcode,
        "barcode": barcode,
        "productname": proname,
        "branchid" : branch
    }
    console.log("data", data)
    fetch(global.url + "currentStockReport", {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
    .then((response)=> response.json())
    .then((responseJson)=>{
        console.log("stock report", responseJson)
        setSource(responseJson.data)
    })
    .catch((error)=>{
        console.log(error)
    })
  }

  const listtypeChange = (event) => {
    setListtype(event.target.value);
  };

  const onExport = () => {
    var company = JSON.parse(localStorage.getItem("company"))
    var data = {
      companyid: company
    }
      fetch(global.url + "excelExportCurrentStock", {
          method: "POST",
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
      })
      .then((response)=> response.json())
      .then((responseJson)=>{
        console.log("link", responseJson)
        if(responseJson.error == false){
          console.log("link", responseJson.data)
            setDownloadlink(responseJson.data)
        }
                
      })
      .catch((error)=>{
          console.log(error)
      })
  }

  const filter = () => {
    
    }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
    return(
        <div className="main-content">
        <Paper sx={{ width: '100%', overflow: 'hidden', padding: 2}}>
            <div style={{flexDirection: 'row', justifyContent: 'space-between', display: 'flex'}}>
               <p style={{fontSize:25,  marginBottom: 0, fontWeight: 'bold'}}>Current Stock Report</p>
               
            </div>
            <Row>
                    <Col sm={3}>
                        <FormControl sx={{ width: '100%', mt: 3 }}>
                            <TextField name="Zone"
                            onChange={(e) => searchProduct(e.target.value, "", "")}
                            autoComplete="off" id="outlined-basic" label="Referal code search" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm={3} >
                        <FormControl sx={{ width: '100%', mt: 3 }}>
                            <TextField name="Zone"
                            onChange={(e) => searchProduct("", e.target.value, "")}
                            autoComplete="off" id="outlined-basic" label="Bar code search" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm={3} >
                         <FormControl sx={{ width: '100%', mt: 3 }}>
                            <TextField name="Zone"
                            onChange={(e) => searchProduct("", "", e.target.value)}
                            autoComplete="off" id="outlined-basic" label="Product Name search" variant="outlined"  size="small"/>
                        </FormControl>
                    </Col>
                    <Col sm={3}>
                        <FormControl style={{width: "100%", marginTop: 24}}>
                                <InputLabel id="demo-simple-select-helper-label">List type</InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={listtype}
                                    label="Age"
                                    onChange={listtypeChange}
                                    size="small"                                    
                                >
                                    <MenuItem value={"0"}>Batchwise</MenuItem>
                                    <MenuItem value={"1"}>Product master wise</MenuItem>
                                </Select>
                        </FormControl>
                    </Col>
                    {/* <Col sm={6} style={{marginTop: 25}}>
                        <Button onClick={filter} style={{backgroundColor: '#92c99f', border: '#92c99f'}} variant="contained">Filter</Button>
                    </Col> */}
                    <Col sm={12} style={{marginTop: 25}}>
                        
                        {
                            downloadlink == "" ? 
                            <Button style={{backgroundColor: '#92c99f', border: '#92c99f', float: "right"}} onClick={() => onExport()} variant="contained">Start Excell Export</Button>:
                            <a style={{float: "right"}} href={global.excell + downloadlink}  target="_blank" download>
                                <Button style={{backgroundColor: '#92c99f', border: '#92c99f'}} variant="contained">Download File</Button>
                            </a>
                        }
                        
                    </Col>
                </Row>
            <Row style={{marginTop: 20}}>
                <Col xs={12}>
                    <TableContainer sx={{ maxHeight: 440 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth, color: '#777', fontWeight: 'bold', fontSize: 16 }}
                                    colSpan={column.span == true ? 2: 1}
                                >
                                    {column.label}
                                    
                                </TableCell>
                                ))}
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {source
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                return (
                                    <TableRow  tabIndex={-1} key={row.code}>
                                        <TableCell >
                                            {row.product_refcode}
                                        </TableCell>
                                        <TableCell >
                                            {row.pb_inter_barcode}
                                        </TableCell>
                                        <TableCell >
                                            {row.product_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.color_name}
                                        </TableCell>
                                        <TableCell >
                                            {row.size_name}
                                        </TableCell>
                                        {
                                            listtype == 0 ? 
                                            <TableCell >
                                                {row.pb_stock}
                                            </TableCell> :
                                            <TableCell >
                                                {row.total_stock}
                                            </TableCell>
                                        }
                                    </TableRow>
                                );
                                })}
                            </TableBody>
                        </Table>
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={source.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                </Col>
            </Row>
          </Paper> 
      </div>
    )
}
export default CurrentStockReport;