import React, {Component, useEffect, useState} from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import DashboardLayoutRoute from '../src/components/layout/dashboardLayout'
import AuthLayoutRoute from '../src/components/layout/authLayout'
import Login from '../src/components/auth/login';
import State from './components/master/state';
import District from './components/master/district';
import Company from './components/master/company';
import Onboard from './components/Onboard/Onboard';
import Branch from './components/master/Branch';
import RouteName from './components/master/Route';
import Area from './components/master/Area';
import VehicleType from './components/master/VehicleType';
import Vehicle from './components/master/Vehicle';
import Godown from './components/master/Godown';
import Price from './components/master/Price';
import Counter from './components/master/Counter';
import Discount from './components/master/Discount';
import Credit from './components/master/Credit';
import Group from './components/Accounts/Group';
import Promoter from './components/Accounts/Promoter';
import Representative from './components/Accounts/Representative';
import Designation from './components/Accounts/Designation';
import Bank from './components/Accounts/Bank';
import BankMaster from './components/Accounts/BankMaster';
import Account from './components/Accounts/AccountComponent/Account';
import Customer from './components/Accounts/CustomerComponent/Customer';
import Supplier from './components/Accounts/SupplierComponent/Supplier';
import Employee from './components/Accounts/Employee/Employee';
import PaymentVoucher from './components/transaction/payment/payment-voucher';
import Salary from './components/transaction/payment/salary';
import ReceiptVoucher from './components/transaction/receipt/receipt-voucher';
import JournalVoucher from './components/transaction/journal/journal-voucher';
import BillSettings from './components/transaction/Bill-Settings/BillSettings';
// import TaxSettings from './components/transaction/Tax-Settings/TaxSettings';
import Contra from './components/transaction/Contra/Contra';
import PDC from './components/transaction/PDC/PDC';
// import Sales from './components/transaction/Sales/Sales';
import Sales from './components/transaction/Sales/fetchsales1';
import RetailSales from './components/transaction/Retail-Sale/RetailSales';
import Category from './components/Products/Category';
import SubCategory from './components/Products/SubCategory';
import Department from './components/Products/Department';
import Segment from './components/Products/Segment';
import Subsegment from './components/Products/Subsegment';
import Locationdisplay from './components/Products/Locationdisplay';
import Section from './components/Products/Section';
import Unit from './components/Products/Unit';
import Tax from './components/Products/Tax';
import Color from './components/Products/Color';
import Size from './components/Products/Size';
import Products from './components/Products/ProductsComponent/product';
import Comboproduct from './components/Products/comboproducts/fetchcombo';
import PriceLevel from './components/Products/PriceLevel';
import HSN from './components/Products/HSN';
import PaymentReport from './components/Reports/PaymentReport';
import ReceiptReport from './components/Reports/ReceiptReport';
import JournalReport from './components/Reports/JournalReport';
import SalesReport from './components/Reports/SalesReport';
import Salesreturn from './components/Reports/Salesreturnreport';
import PurchaseReport from './components/Reports/PurchaseReport';
import Purchasereturn from './components/Reports/Purchasereturnreport';
import Salesorderreport from './components/Reports/salesorderreport';
import Userwisesalesorder from './components/Reports/userwisesaleorder';
import Purchaseorderreport from './components/Reports/purchaseorderreport';
import UserManagement from './components/User/UserManagement';
import Userrole from './components/User/userrole';
import Purchase from './components/transaction/Purchase/fetchpurchase';
import Salesorder from './components/transaction/salesOrder/salesOrder';
import Purchaseorder from './components/transaction/purchaseOrder/fetchpurchaseorder';
import Purchaserequest from './components/transaction/purchaseOrder/purchaserequest';
import Quotation from './components/transaction/Quotation/quotation';
import POS from './components/transaction/pos/pos';
import UserAttendance from './components/User/UserAttendance';
import Dashboard from './components/Dashboard/dashboard';
import Filemanager from './components/Filemanager/filemanager';
import Attendance from './components/User/Attendance';
import Userlog from './components/User/Userlog';
import WebsiteBanner from './components/websites/WebsiteBanner';
import LatestWorks from './components/websites/Latestwork';
import Enquiries from './components/websites/Enquiries';
import ClientLogos from './components/websites/ClientLogos';
import JobListing from './components/websites/JobListing';
import JobApplication from './components/websites/JobApplication';
import GroupMailing from './components/Mail/GroupMailing';
import Widgets from './components/Widgets/AppWidgets';
import LPO from './components/Creditors/LPO/LPO';
import GRN from './components/Creditors/GRN/GRN';
import PurchaseApproval from './components/Creditors/Approval/PurchaseApproval';
import Purchases from './components/Creditors/Purchase/Purchases';
import PurchasesReturn from './components/Creditors/Return/PurchaseReturn';
import { RiCoupon2Fill } from 'react-icons/ri';
import Coupon from './components/Discount/Coupon/Coupon';
import Promotion from './components/Discount/Promotion/Promotion';
import Quatation from './components/Sales/Quatation';
import SalesOrder from './components/Sales/SalesOrder';
import SalesApproval from './components/Sales/SalesApproval';
import Salesordercancel from './components/Sales/Salesorderreplacelist';
import Displaywarehouse from './components/Sales/displaywarehouse';
import Salesorderreplace from './components/Sales/salesorderreplace';
import DeliveryBoyAssign from './components/Sales/DeliveryboyAssign';
import Sale from './components/Sales/Sales';
import DeliveryNote from './components/Sales/DeliveryNote';
import SaleReturn from './components/Sales/SalesReturn';
import DeliveryReturn from './components/Sales/DeliveryReturn';
import StockAdjustment from './components/StockEntries/fetchstockadjustment';
import Historystock from './components/StockEntries/Historystock';
import StockTransfer from './components/StockEntries/StockTransfer';
import StockReceipt from './components/StockEntries/StockReceipt';
import GodownTransfer from './components/StockEntries/GodownTransfer';
import GodownReceipt from './components/StockEntries/GodownReceipt';
import Packing from './components/StockEntries/Packing';
import Unpacking from './components/StockEntries/Unpacking';
import DamageEntry from './components/StockEntries/DamageEntry';
import CurrentStockReport from './components/StockReport/CurrentStockReport';
import Detailedstock from './components/StockReport/detailedstock';

import OrderDelivery from './components/Reports/OrderDelivery'
import DeliveryExecutive from './components/Reports/DeliveryExecutive';
import DeliveryBoy from './components/Reports/DeliveryReport';

import Salesreplace from './components/transaction/Sales/Salesreplacement';
import SalesExecutive from './components/transaction/Sales/SalesExecutive';

import Stockreport from './components/StockReport/stockreport';
import Inventory from './components/StockReport/Inventory';

import Cashbook from './components/Reports/Accounts/cashbook';
import Cancelorderreport from './components/Reports/cancelorderreport';
import Bankbook from './components/Reports/Accounts/bankbook';
import Ledgersummary from './components/Reports/Accounts/ledgersummary';
import Ledgerdetails from './components/Reports/Accounts/ledgerdetails';
import Daybook from './components/Reports/Accounts/daybook';
import Profitandloss from './components/Reports/Accounts/profit&loss';
import Trialbalance from './components/Reports/Accounts/trialbalance';
import Balancesheet from './components/Reports/Accounts/balancesheet';
import Cancelledproduct from './components/Reports/cancelledProduct';

import Userlogs from './components/Reports/userlogs';

import Commonsettings from './components/settings/commonsettings';

import Print from './components/transaction/pos/Invoice';
import Saleprint from './components/transaction/pos/InvoiceBill';
import Saleprint1 from './components/transaction/pos/invoiceBill1';
import Possummary from './components/transaction/pos/possummary';
import DigitalWarehouseReport from './components/StockReport/DigitalWarehouseReport';
import Purchaserequestprint from './components/transaction/pos/Purchaserequestinvoice';
import Purchaseorderprint from './components/transaction/pos/Purchaseorderinvoice';
import Purchasebill from './components/transaction/pos/purchasebill';

import Poslogin from '../src/components/auth/poslogin';

import Banner from './components/Ecommerce/Banner';
import DeliveryOrder from './components/Ecommerce/Delivery';
import Quotationlist from './components/transaction/Quotation/Quotationlist';
import notification from './components/Widgets/notification';
// import Newpurchaseprint from './components/transaction/pos/Newpurchaseinvoice';
import Purchaseprint from './components/transaction/pos/Purchaseprint';
import FinantialYear from './components/settings/FinancialYear'
import Detailsofstocks from './components/StockReport/Detailsofstocks';
import OrderType from './components/master/OrderType';
import PosReport from './components/Reports/PosSalesReport';
import SalesSingleReturn from './components/transaction/Sales/SingleSalesReturn';
import PurchaseSingleReturn from './components/transaction/Purchase/SinglePurchaseReturn';
import SalesSummaryReport from './components/Reports/SalesSummaryReport';
import TransferOut from './components/StockEntries/TransferOut';
import productwise from './components/Reports/productwisereport';
import Utilities from './components/Utilities/Utilities';
import Purchasereturntaxreport from './components/Reports/Taxreport/purchasereturntaxreport';
import Purchasetaxreport from './components/Reports/Taxreport/purschasetaxreport' 
import Salesreturntaxreport from './components/Reports/Taxreport/salesreturntaxreport';
import Salestaxreport from './components/Reports/Taxreport/salestaxreport';

function AppNavigator() { 
  return (
      <Router>
        <Switch>
          <DashboardLayoutRoute exact path="/Reports/userlogs" component={Userlogs} />
          <DashboardLayoutRoute exact path="/saleinvoice/:id" component={Saleprint} />
          <DashboardLayoutRoute exact path="/saleorderinvoice/:id" component={Saleprint1} />
          <DashboardLayoutRoute exact path="/purchaseinvoice/:id" component={Purchasebill} />
          <DashboardLayoutRoute exact path="/purchaserequestinvoice/:id" component={Purchaserequestprint} />
          <DashboardLayoutRoute exact path="/purchaseorderinvoice/:id" component={Purchaseorderprint} />
          <DashboardLayoutRoute exact path="/notification" component={notification} />
          <DashboardLayoutRoute exact path="/detailsofstocks/:id/:open/:fromdate/:todate" component={Detailsofstocks} />
          {/* <DashboardLayoutRoute exact path='/newpurchaseinvoice' component={Newpurchaseprint} /> */}
          <DashboardLayoutRoute exact path='/purchaseprint/:id' component={Purchaseprint} />
          <DashboardLayoutRoute exact path='/finatialyear' component={FinantialYear} />
        
          <DashboardLayoutRoute exact path="/master/state" component={State} />
          <DashboardLayoutRoute exact path="/master/district" component={District} />
          <DashboardLayoutRoute exact path="/master/company" component={Company} />
          <DashboardLayoutRoute exact path="/master/Branch" component={Branch} />
          <DashboardLayoutRoute exact path="/master/Route" component={RouteName} />
          <DashboardLayoutRoute exact path="/master/Area" component={Area} />
          <DashboardLayoutRoute exact path="/master/VehicleType" component={VehicleType} />
          <DashboardLayoutRoute exact path="/master/Vehicle" component={Vehicle} />
          <DashboardLayoutRoute exact path="/master/Godown" component={Godown} />
          <DashboardLayoutRoute exact path="/master/Price" component={Price} />
          <DashboardLayoutRoute exact path="/master/Counter" component={Counter} />
          <DashboardLayoutRoute exact path="/master/Discount" component={Discount} />
          <DashboardLayoutRoute exact path="/master/Credit" component={Credit} />
          <DashboardLayoutRoute exact path="/master/Ordertype" component={OrderType} />
          

          <DashboardLayoutRoute exact path="/Accounts/Group" component={Group} />
          <DashboardLayoutRoute exact path="/Accounts/Account" component={Account} />
          <DashboardLayoutRoute exact path="/Accounts/Customer" component={Customer} />
          <DashboardLayoutRoute exact path="/Accounts/Supplier" component={Supplier} />
          <DashboardLayoutRoute exact path="/Accounts/Promoter" component={Promoter} />
          <DashboardLayoutRoute exact path="/Accounts/Representative" component={Representative} />
          <DashboardLayoutRoute exact path="/Accounts/Designation" component={Designation} />
          <DashboardLayoutRoute exact path="/Accounts/Employee" component={Employee} />
          <DashboardLayoutRoute exact path="/Accounts/Bank" component={Bank} />
          <DashboardLayoutRoute exact path="/Accounts/BankMaster" component={BankMaster} />

          <DashboardLayoutRoute exact path="/transaction/paymentvoucher/:id" component={PaymentVoucher} />
          <DashboardLayoutRoute exact path="/transaction/Salary/:id" component={Salary} />
          
          <DashboardLayoutRoute exact path="/transaction/receiptvoucher/:id" component={ReceiptVoucher} />
          <DashboardLayoutRoute exact path="/transaction/journalvoucher/:id" component={JournalVoucher} />
          {/* <DashboardLayoutRoute exact path="/transaction/TaxSettings" component={TaxSettings} /> */}
          <DashboardLayoutRoute exact path="/transaction/BillSettings" component={BillSettings} />
          <DashboardLayoutRoute exact path="/transaction/RetailSales" component={RetailSales} />
          <DashboardLayoutRoute exact path="/transaction/Sales/:id" component={Sales} />
          <DashboardLayoutRoute exact path="/Sales/pos" component={POS} />
          <DashboardLayoutRoute exact path="/transaction/Salesorder/:id" component={Salesorder} />
          <DashboardLayoutRoute exact path="/transaction/Purchase/:id" component={Purchase} />
          <DashboardLayoutRoute exact path="/transaction/Purchaseorder/:id" component={Purchaseorder} />
          <DashboardLayoutRoute exact path="/transaction/Purchaserequest" component={Purchaserequest} />
          <DashboardLayoutRoute path="/transaction/Salesreplace" component={Salesreplace} />
          <DashboardLayoutRoute path="/transaction/Salesexecutive" component={SalesExecutive} />

          <DashboardLayoutRoute path="/transaction/Quotation/:id" component={Quotation} />
          <DashboardLayoutRoute path="/transaction/Quotationlist" component={Quotationlist} />

          <DashboardLayoutRoute path="/transaction/salessinglereturn" component={SalesSingleReturn} />
          <DashboardLayoutRoute path="/transaction/purchasesinglereturn/:id" component={PurchaseSingleReturn} />

          <DashboardLayoutRoute path="/Sales/Salesorderreplacelist" component={Salesordercancel} />
          <DashboardLayoutRoute path="/Sales/salesorderreplace/:id" component={Salesorderreplace} />
          <DashboardLayoutRoute path="/Sales/displaywarehouse" component={Displaywarehouse} />

          <DashboardLayoutRoute exact path="/transaction/Contra/:id" component={Contra} />
          <DashboardLayoutRoute exact path="/transaction/PDC" component={PDC} />

          <DashboardLayoutRoute exact path="/Creditors/LPO" component={LPO} />
          <DashboardLayoutRoute exact path="/Creditors/GRN" component={GRN} />
          <DashboardLayoutRoute exact path="/Creditors/PurchaseApproval" component={PurchaseApproval} />
          <DashboardLayoutRoute exact path="/Creditors/Purchases" component={Purchases} />
          <DashboardLayoutRoute exact path="/Creditors/PurchasesReturn" component={PurchasesReturn} />

          <DashboardLayoutRoute exact path="/Products/Category" component={Category} />
          <DashboardLayoutRoute exact path="/Products/SubCategory" component={SubCategory} />
          <DashboardLayoutRoute exact path="/Products/Section" component={Department} />
          <DashboardLayoutRoute exact path="/Products/Department" component={Section} />
          <DashboardLayoutRoute exact path="/Products/Unit" component={Unit} />
          <DashboardLayoutRoute exact path="/Products/Tax" component={Tax} />
          <DashboardLayoutRoute exact path="/Products/Hsn" component={HSN} />
          <DashboardLayoutRoute exact path="/Products/Products" component={Products} />
          <DashboardLayoutRoute exact path="/Products/comboproducts" component={Comboproduct} />
          <DashboardLayoutRoute exact path="/Products/PriceLevel" component={PriceLevel} />
          <DashboardLayoutRoute exact path="/Products/color" component={Color} />
          <DashboardLayoutRoute exact path="/Products/size" component={Size} />
          <DashboardLayoutRoute exact path="/Products/Segment" component={Segment} />
          <DashboardLayoutRoute exact path="/Products/Subsegment" component={Subsegment} />
          <DashboardLayoutRoute exact path="/Products/Locationdisplay" component={Locationdisplay} />

          <DashboardLayoutRoute path="/Reports/Taxreport/purchasereturntaxreport" component={Purchasereturntaxreport} />
          <DashboardLayoutRoute path="/Reports/Taxreport/purschasetaxreport" component={Purchasetaxreport} />
          <DashboardLayoutRoute path="/Reports/Taxreport/salesreturntaxreport" component={Salesreturntaxreport} />
          <DashboardLayoutRoute path="/Reports/Taxreport/salestaxreport" component={Salestaxreport} />                  
          
          <DashboardLayoutRoute path="/Reports/PaymentReport" component={PaymentReport} />
          <DashboardLayoutRoute path="/Reports/ReceiptReport" component={ReceiptReport} />
          <DashboardLayoutRoute path="/Reports/JournalReport" component={JournalReport} />
          <DashboardLayoutRoute path="/Reports/SalesReport" component={SalesReport} />
          <DashboardLayoutRoute path="/Reports/salesreturn" component={Salesreturn} />
          <DashboardLayoutRoute path="/Reports/PurchaseReport" component={PurchaseReport} />
          <DashboardLayoutRoute path="/Reports/purchasereturn" component={Purchasereturn} />
          <DashboardLayoutRoute path="/Reports/Salesorderreport" component={Salesorderreport} />
          <DashboardLayoutRoute path="/Reports/Purchaseorderreport" component={Purchaseorderreport} />
          <DashboardLayoutRoute path="/Reports/userwisesalesorder" component={Userwisesalesorder} />
          <DashboardLayoutRoute path="/Reports/orderdelivery" component={OrderDelivery} />
          <DashboardLayoutRoute path="/Reports/deliveryexecutive" component={DeliveryExecutive} />
          <DashboardLayoutRoute path="/Reports/deliveryboy" component={DeliveryBoy} />
          <DashboardLayoutRoute path="/Reports/PosReport" component={PosReport} />
          <DashboardLayoutRoute path="/Reports/SalesSummary" component={SalesSummaryReport} />
          <DashboardLayoutRoute path="/Reports/SalesProductWiseReport" component={productwise} />

          <DashboardLayoutRoute path="/Reports/cashbook" component={Cashbook} />
          <DashboardLayoutRoute path="/Reports/bankbook" component={Bankbook} />
          <DashboardLayoutRoute path="/Reports/ledgersummary" component={Ledgersummary} />
          <DashboardLayoutRoute path="/Reports/ledgerdetails" component={Ledgerdetails} />
          <DashboardLayoutRoute path="/Reports/daybookdetails" component={Daybook} />
          <DashboardLayoutRoute path="/Reports/profitandloss" component={Profitandloss} />
          <DashboardLayoutRoute path="/Reports/trialbalance" component={Trialbalance} />
          <DashboardLayoutRoute path="/Reports/balancesheet" component={Balancesheet} />
          <DashboardLayoutRoute path="/inventory" component={Inventory} />

          

          <DashboardLayoutRoute path="/Discount/Coupon" component={Coupon} />
          <DashboardLayoutRoute path="/Discount/Promotion" component={Promotion} />

          <DashboardLayoutRoute path="/Sales/Quatation" component={Quatation} />
          <DashboardLayoutRoute path="/Sales/SalesOrder" component={SalesOrder} />
          <DashboardLayoutRoute path="/Sales/SalesApproval" component={SalesApproval} />
          <DashboardLayoutRoute path="/Sales/DeliveryBoyAssign/:id/:invoivefrom/:invoiceto" component={DeliveryBoyAssign} />
          <DashboardLayoutRoute path="/Sales/Sales" component={Sale} />
          <DashboardLayoutRoute path="/Sales/DeliveryNote/:id" component={DeliveryNote} />
          <DashboardLayoutRoute path="/Sales/SalesReturn" component={SaleReturn} />
          <DashboardLayoutRoute path="/Sales/DeliveryReturn" component={DeliveryReturn} />

          <DashboardLayoutRoute path="/StockEntries/StockAdjustment" component={StockAdjustment} />
          <DashboardLayoutRoute path="/StockEntries/Historystock" component={Historystock} />
          <DashboardLayoutRoute path="/StockEntries/TransferIn" component={StockTransfer} />
          <DashboardLayoutRoute path="/StockEntries/TransferOut" component={TransferOut} />
          <DashboardLayoutRoute path="/StockEntries/StockReceipt" component={StockReceipt} />
          <DashboardLayoutRoute path="/StockEntries/GodownTransfer" component={GodownTransfer} />
          <DashboardLayoutRoute path="/StockEntries/GodownReceipt" component={GodownReceipt} />
          <DashboardLayoutRoute path="/StockEntries/Packing" component={Packing} />
          <DashboardLayoutRoute path="/StockEntries/Unpacking" component={Unpacking} />
          <DashboardLayoutRoute path="/StockEntries/DamageEntry" component={DamageEntry} />
          
          <DashboardLayoutRoute path="/User/UserAttendance" component={UserAttendance} />
          <DashboardLayoutRoute path="/User/UserManagement" component={UserManagement} />
          <DashboardLayoutRoute path="/User/Userrole" component={Userrole} />
          <DashboardLayoutRoute path="/User/Attendance" component={Attendance} />
          <DashboardLayoutRoute path="/User/userlog" component={Userlog} />

          <DashboardLayoutRoute path="/dashboard" component={Dashboard} />
          <DashboardLayoutRoute path="/StockReport/CurrentStockReport" component={CurrentStockReport} />
          <DashboardLayoutRoute path="/Filemanager/filemanger" component={Filemanager} />

          <DashboardLayoutRoute path="/websites/WebsiteBanner" component={WebsiteBanner} />
          <DashboardLayoutRoute path="/websites/latestWorks" component={LatestWorks} />
          <DashboardLayoutRoute path="/websites/Enquiries" component={Enquiries} />
          <DashboardLayoutRoute path="/websites/ClientLogos" component={ClientLogos} />
          <DashboardLayoutRoute path="/websites/JobListing" component={JobListing} />
          <DashboardLayoutRoute path="/websites/JobApplication" component={JobApplication} />

          <DashboardLayoutRoute path="/Reports/cancelorder" component={Cancelorderreport} />
          <DashboardLayoutRoute path="/Reports/cancelproduct" component={Cancelledproduct} />
          
          <DashboardLayoutRoute path="/settings/commonsettings" component={Commonsettings} />

          <DashboardLayoutRoute path="/Mail/GroupMailing" component={GroupMailing} />
          <DashboardLayoutRoute path="/Widgets/Widgets" component={Widgets} />
          <DashboardLayoutRoute path="/print/:id" component={Print} />

          <DashboardLayoutRoute path="/Sales/possummary" component={Possummary} />

          <DashboardLayoutRoute path="/stockvalue" component={Stockreport} />
          <DashboardLayoutRoute path="/detailedstock" component={Detailedstock} />
          <DashboardLayoutRoute path="/digitalwarehousereport" component={DigitalWarehouseReport} />

          <DashboardLayoutRoute path="/Ecommerce/Banner" component={Banner} />
          <DashboardLayoutRoute path="/Ecommerce/Delivery" component={DeliveryOrder} />

          <DashboardLayoutRoute path="/Utilities" component={Utilities} />

          <AuthLayoutRoute exact path="/login/:id" component={Login} />
          <AuthLayoutRoute exact path="/poslogin/:id" component={Poslogin} />
          <AuthLayoutRoute exact path="/" component={Onboard} />
        </Switch>
      </Router>
  );
}

export default AppNavigator;
